import * as types from './BankAccount.actionTypes';

import { initialState } from './BankAccount.initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_BANK_ACCOUNTS_SUCCESS:
      return {
        ...state,
        list: action.payload.list,
      };

    case types.FETCH_BANK_ACCOUNT_DETAILS_SUCCESS:
      return { ...state, single: action.payload };

    case types.FETCH_ACCOUNTS_SUCCESS:
      const { itemID } = action.meta;
      return {
        ...state,
        accounts: action.payload.map(item => ({ ...item, itemID })),
      };

    default:
      return { ...state };
  }
};

import { map } from 'lodash';
import { all, fork } from 'redux-saga/effects';

import * as authSagas from './Auth/Auth.sagas';
import * as registerSagas from './Register/Register.sagas';
import * as storesSagas from './Stores/Stores.sagas';
import * as bankSagas from './BankAccount/BankAccount.sagas';
import * as transactionSagas from './Transaction/Transaction.sagas';
import * as userSagas from './User/User.sagas';

export default function* rootSaga() {
  yield all(map(authSagas, item => fork(item)));
  yield all(map(registerSagas, item => fork(item)));
  yield all(map(storesSagas, item => fork(item)));
  yield all(map(bankSagas, item => fork(item)));
  yield all(map(transactionSagas, item => fork(item)));
  yield all(map(userSagas, item => fork(item)));
}

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

const StyledWrapper = styled.div`
  display: none;

  &.modal--opened {
    display: block;
  }

  .modal__mask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000;
    opacity: 0.45;
    z-index: 999;
  }

  .modal__content {
    position: fixed;
    top: 50%;
    left: 0;
    right: 0;
    background-color: #f3f5f9;
    transform: translateY(-50%);
    z-index: 1000;
    max-height: 70vh;
    overflow: auto;

    padding: 60px 30px 30px;
  }
`;

const Modal = ({ onClose, children, visible }) => {
  return (
    <StyledWrapper
      className={classnames('modal', { 'modal--opened': visible })}
    >
      <div className="modal__mask" onClick={onClose} />
      <div className="modal__content">{children}</div>
    </StyledWrapper>
  );
};

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.node,
    PropTypes.string,
  ]),
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

Modal.defaultProps = {
  children: null,
  visible: false,
};

export default Modal;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';

// SOCKET
import Socket from 'socket';

// HELPERS
import { usePrevious } from 'utils/hooks';

// EVENT EMITTER
import EventEmitter from 'event';

// ACTIONS
import { addNotification } from 'redux/GlobalUI/GlobalUI.actions';
import { getUserDetails } from 'redux/Auth/Auth.actions';
import {
  fetchBankAccounts,
  fetchAccounts,
} from 'redux/BankAccount/BankAccount.actions';
import { fetchEmployeeTransactions } from 'redux/Transaction/Transaction.actions';

// SELECTORS
import { createLoadingSelector } from 'redux/ApiStatus/ApiStatus.selectors';
import { userDetailsSelector } from 'redux/Auth/Auth.selectors';
import { storeDetailSelector } from 'redux/Stores/Stores.selectors';

// ICON
import Loading from 'components/Loading/Loading';

// CONSTANTS
import { EVENTS } from 'constants/events';

const loadingSelector = createLoadingSelector([
  'FETCH_USER_DETAILS',
  'FETCH_BANK_ACCOUNTS',
]);

const AuthenticatedRoute = ({ children, history }) => {
  const dispatch = useDispatch();
  const loading = useSelector(loadingSelector);
  const userDetails = useSelector(userDetailsSelector());
  const storeDetail = useSelector(storeDetailSelector());
  const eventEmitter = EventEmitter.getInstance();

  useEffect(() => {
    dispatch(getUserDetails());
    dispatch(fetchBankAccounts());
  }, []);

  useEffect(() => {
    // SOCKET INITIAL
    const socket = Socket.getInstance();
    socket.triggerEvent(EVENTS.REQUEST_TO_PAY, ({ data }) => {
      const { transaction } = data;
      if (transaction) {
        history.push(`/pay?transaction-id=${transaction.transactionID}`);
      }
    });
    // More socket listeners go here...

    // GET ACCOUNTS
    const { defaultBankItemID } = userDetails || {};
    if (defaultBankItemID) {
      dispatch(
        fetchAccounts({
          queryParams: {
            itemID: defaultBankItemID.itemID,
          },
        })
      );
    }
  }, [userDetails]);

  // EventEmitter will be listened from here
  if (!isEmpty(storeDetail)) {
    const storeId = storeDetail.store.storeID;
    const merchantId = userDetails.merchantIDs[0];

    eventEmitter.onEvent(EVENTS.FETCH_EMPLOYEE_TRANSACTIONS, data => {
      const customer = data.dataFromSocket.transaction.customerID;
      dispatch(
        fetchEmployeeTransactions({
          queryParams: {
            merchantId: merchantId,
            storeId: storeId,
            sortBy: 'updatedAt',
            sortDirection: 'DESC',
          },
          callback: () => {
            if (data.status === EVENTS.ACCEPTED_TRANSACTION) {
              dispatch(
                addNotification({
                  type: 'success',
                  message: `${customer.firstName} ${customer.lastName} has accepted the transaction`,
                })
              );
            }

            if (data.status === EVENTS.DENIED_TRANSACTION) {
              dispatch(
                addNotification({
                  type: 'error',
                  message: `${customer.firstName} ${customer.lastName} has denied the transaction`,
                })
              );
            }
          },
        })
      );
    });
  }

  if (loading) return <Loading type="dots" isScreen />;
  if (!userDetails) return null;
  return children;
};

AuthenticatedRoute.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.node,
    PropTypes.func,
  ]),
};
export default withRouter(AuthenticatedRoute);

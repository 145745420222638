import Transaction from 'models/Transaction.model';
import moment from 'moment';
import { SOURCES } from './sources';
import { DATE_FORMAT } from 'constants/index';

export const TRANSACTIONS = [
  new Transaction({
    id: 1,
    source: SOURCES[0],
    createdAt: moment('2019-11-30').format(DATE_FORMAT),
    amount: 100,
  }),
  new Transaction({
    id: 2,
    source: SOURCES[1],
    createdAt: moment('2019-11-30').format(DATE_FORMAT),
    amount: 234,
  }),
  new Transaction({
    id: 3,
    source: SOURCES[2],
    createdAt: moment('2019-12-30').format(DATE_FORMAT),
    amount: 504,
  }),
  new Transaction({
    id: 4,
    source: SOURCES[3],
    createdAt: moment('2019-12-30').format(DATE_FORMAT),
    amount: 94,
  }),
];

export const TRANSACTION_STATUS = {
  DENIED: 'denied',
  ACCEPTED: 'accepted',
  PENDING: 'pending',
  NOT_FOUND: 'not-found',
};

import * as types from './Transaction.actionTypes';

export const fetchTransactionsFlow = {
  request: action => ({
    type: types.FETCH_TRANSACTIONS_REQUEST,
    ...action,
  }),
  success: (data, meta) => ({
    type: types.FETCH_TRANSACTIONS_SUCCESS,
    payload: data,
    meta,
  }),
  failure: err => ({
    type: types.FETCH_TRANSACTIONS_FAILURE,
    payload: err,
  }),
};

export const fetchTransactions = fetchTransactionsFlow.request;

export const fetchTransactionDetailsFlow = {
  request: ({ queryParams }) => ({
    type: types.FETCH_TRANSACTION_DETAILS_REQUEST,
    queryParams,
  }),
  success: (data, meta) => ({
    type: types.FETCH_TRANSACTION_DETAILS_SUCCESS,
    payload: data,
    meta,
  }),
  failure: err => ({
    type: types.FETCH_TRANSACTION_DETAILS_FAILURE,
    payload: err,
  }),
};

export const fetchTransactionDetails = fetchTransactionDetailsFlow.request;

export const updatePaymentDetails = payload => ({
  type: types.UPDATE_PAYMENT_DETAILS,
  payload,
});

export const resetTransacionDetails = () => ({
  type: types.RESET_TRANSACTION_DETAILS,
});

export const approveTransactionFlow = {
  request: action => ({
    type: types.APPROVE_TRANSACTION_REQUEST,
    ...action,
  }),
  success: (data, meta) => ({
    type: types.APPROVE_TRANSACTION_SUCCESS,
    payload: data,
    meta,
  }),
  failure: err => ({
    type: types.APPROVE_TRANSACTION_FAILURE,
    payload: err,
  }),
};

export const approveTransaction = approveTransactionFlow.request;

export const declineTransactionFlow = {
  request: action => ({
    type: types.DECLINE_TRANSACTION_REQUEST,
    ...action,
  }),
  success: (data, meta) => ({
    type: types.DECLINE_TRANSACTION_SUCCESS,
    payload: data,
    meta,
  }),
  failure: err => ({
    type: types.DECLINE_TRANSACTION_FAILURE,
    payload: err,
  }),
};

export const declineTransaction = declineTransactionFlow.request;

export const createTransactionFlow = {
  request: ({ params, callback }) => ({
    type: types.CREATE_TRANSACTION_REQUEST,
    params,
    callback,
  }),
  success: data => ({
    type: types.CREATE_TRANSACTION_SUCCESS,
    payload: data,
  }),
  failure: err => ({
    type: types.CREATE_TRANSACTION_FAILURE,
    payload: err,
  }),
};

export const createTransaction = createTransactionFlow.request;

export const fetchEmloyeeTransactionsFlow = {
  request: ({ queryParams, callback }) => ({
    type: types.FETCH_EMPLOYEE_TRANSACTIONS_REQUEST,
    queryParams,
    callback,
  }),
  success: data => ({
    type: types.FETCH_EMPLOYEE_TRANSACTIONS_SUCCESS,
    payload: data,
  }),
  failure: err => ({
    type: types.FETCH_EMPLOYEE_TRANSACTIONS_FAILURE,
    payload: err,
  }),
};

export const fetchEmployeeTransactions = fetchEmloyeeTransactionsFlow.request;

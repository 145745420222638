// ACTION TYPES
import * as types from './GlobalUI.actionTypes';

export const addNotification = ({ type, message }) => ({
  type: types.ADD_NOTIFICATION,
  notification: {
    type,
    message,
  },
});

export const removeNotification = ({ notificationIndex }) => ({
  type: types.REMOVE_NOTIFICATION,
  notificationIndex,
});

export const toggleMenu = open => ({
  type: types.TOGGLE_MENU,
  open,
});

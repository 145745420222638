import { initialState } from './User.initialState';
import * as types from './User.actionTypes';

const generateProfileURL = ({ profilePhoto }) => {
  if (profilePhoto) {
    return `https${profilePhoto.split('http')[1]}`;
  }

  return '/assets/images/default_profile.png';
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_USER_INFORMATION_SUCCESS: {
      return {
        ...state,
        error: null,
        detail: {
          ...action.payload,
          profilePhoto: generateProfileURL(action.payload),
        },
      };
    }

    case types.FETCH_USER_INFORMATION_FAILURE: {
      return {
        ...state,
        detail: null,
        error: action.payload,
      };
    }

    default:
      return { ...state };
  }
};

import Source from 'models/RewardSource.model';

export const SOURCES = [
  new Source({
    id: 1,
    name: 'Google',
    logo: '/assets/images/logos/google.svg',
  }),
  new Source({
    id: 2,
    name: 'Tesla',
    logo: '/assets/images/logos/tesla.svg',
  }),
  new Source({
    id: 3,
    name: 'Weed - Better Off',
    logo: '/assets/images/logos/weed.svg',
  }),
  new Source({
    id: 4,
    name: 'Uber',
    logo: '/assets/images/logos/uber.svg',
  }),
];

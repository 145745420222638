import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';

const StyledContainer = styled.div`
  padding-left: 30px;
  padding-right: 30px;
`;

const Card = ({ children, ...rest }) => (
  <StyledContainer {...rest}>{children}</StyledContainer>
);

Card.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.node,
    PropTypes.string,
  ]),
};

Card.defaultProps = {
  children: null,
};

export default withTheme(Card);

export const initialState = {
  login: {
    details: {
      phone: '',
      pinCode: [],
    },
    activeStep: 1,
  },
  userDetails: null,
};

import { BrowserRouter as Router, Route } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';
import { ThemeProvider } from 'styled-components';
import { ConnectedRouter } from 'connected-react-router';

// HISTORY
import { history } from 'redux/store';

// Import CSS reset and Global Styles
import GlobalStyle from 'styles/globals';

// ROUTES
import { RegisterRoutes, ProtectedRoute } from 'routes/';

// COMPONENTS
import NotificationsProvider from 'components/Notifications/NotificationsProvider';
import AnimatedSwitch from 'components/AnimatedSwitch/AnimatedSwitch';

// CONSTANTS
import Loading from 'components/Loading/Loading';
import { THEME } from './constants/theme';

const Home = lazy(() => import('./pages/Home.jsx'));
const About = lazy(() => import('./pages/About.jsx'));
const PageLogin = lazy(() => import('./pages/PageLogin/PageLogin'));
const PageRewards = lazy(() => import('./pages/PageRewards/PageRewards'));
const PageFavorites = lazy(() => import('./pages/PageFavorites/PageFavorites'));
const PageFavoriteStores = lazy(() =>
  import('./pages/PageFavorites/modules/FavoriteStores')
);
const PageVerifyID = lazy(() => import('./pages/PageVerifyID/PageVerifyID'));

// Employee
const PageLoginEmployee = lazy(() =>
  import('./pages/Employee/PageLogin/PageLogin')
);

const App = () => (
  <ThemeProvider theme={THEME}>
    <GlobalStyle />
    <NotificationsProvider>
      <ConnectedRouter history={history}>
        <Suspense fallback={<Loading type="dots" isScreen />}>
          <AnimatedSwitch>
            <Route exact path="/" component={Home} />
            <Route exact path="/login" component={PageLogin} />

            {/* Employee Route */}
            <Route exact path="/employee/login" component={PageLoginEmployee} />

            {/* REGISTER */}
            <Route path="/register" component={RegisterRoutes} />

            <Route path="/about" component={About} />

            <Route path="/rewards" component={PageRewards} />
            <Route exact path="/favorites" component={PageFavorites} />
            <Route
              exact
              path="/favorites/stores"
              component={PageFavoriteStores}
            />

            <Route path="/verify-id" component={PageVerifyID} />

            <ProtectedRoute />
          </AnimatedSwitch>
        </Suspense>
      </ConnectedRouter>
    </NotificationsProvider>
  </ThemeProvider>
);

export default App;

import Transaction from 'models/Transaction.model';

import { initialState } from './Transaction.initialState';
import * as types from './Transaction.actionTypes';
import { TRANSACTION_STATUS } from 'constants/transactions';

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        list: action.payload.list
          .filter(item => item.transactionStatus !== TRANSACTION_STATUS.PENDING)
          .map(item => new Transaction(item)),
      };
    }

    case types.FETCH_EMPLOYEE_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        list: action.payload.list,
      };
    }

    case types.FETCH_TRANSACTION_DETAILS_REQUEST: {
      return {
        ...state,
        single: null,
      };
    }

    case types.FETCH_TRANSACTION_DETAILS_FAILURE: {
      return {
        ...state,
        single: {
          transactionStatus: TRANSACTION_STATUS.NOT_FOUND,
          storeID: {},
        },
      };
    }

    case types.RESET_TRANSACTION_DETAILS: {
      return {
        ...state,
        single: null,
      };
    }

    case types.APPROVE_TRANSACTION_SUCCESS: {
      return {
        ...state,
        single: new Transaction({
          ...state.single,
          amount: action.payload.amount,
          bankID: {
            itemID: action.payload.bankID,
          },
        }),
      };
    }

    case types.FETCH_TRANSACTION_DETAILS_SUCCESS: {
      const { amount, storeID, transactionID } = action.payload;
      return {
        ...state,
        single: new Transaction(action.payload),
        payment: {
          transactionID,
          storeName: storeID.name,
          amount: amount,
          tip: 0,
          fee: 0,
          tax: 0,
        },
      };
    }

    case types.UPDATE_PAYMENT_DETAILS: {
      const { amount, fee, tax, tip } = state.payment;
      return {
        ...state,
        payment: {
          ...state.payment,
          [action.payload.key]: action.payload.val,
        },
      };
    }

    case types.CREATE_TRANSACTION_SUCCESS: {
      return {
        ...state,
        fromCreation: action.payload,
      };
    }

    case types.CREATE_TRANSACTION_FAILURE: {
      return {
        ...state,
        fromCreation: {},
      };
    }

    default:
      return { ...state };
  }
};

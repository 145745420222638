import { calculateAmountTotal } from 'utils/number';

export default class Transaction {
  constructor(transaction) {
    Object.keys(transaction).forEach(key => (this[key] = transaction[key]));
    const { amount, fee, tax, tip } = transaction;
    this.amountInUSD = transaction.amount;
    this.amountTotal = calculateAmountTotal({
      amount: this.amountInUSD,
      fee,
      tax,
      tip,
    });
  }
}

import * as types from './Stores.actionTypes';
import { initialState } from './Stores.initialState';
import { forEach } from 'lodash';

const generateLogoURL = ({ logoPhoto }) => {
  if (logoPhoto) {
    return `https${logoPhoto.split('http')[1]}`;
  }

  return '/assets/images/shop.svg';
};

const checkLogoStores = data => {
  forEach(data, v => {
    v.logo = generateLogoURL({ logoPhoto: v.logo });
  });

  return data;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_STORE_DETAIL_SUCCESS: {
      const storeDetail = action.payload;
      storeDetail.store.logo = generateLogoURL({
        logoPhoto: storeDetail.store.logo,
      });
      return {
        ...state,
        store: storeDetail,
      };
    }

    case types.FETCH_STORES_SUCCESS: {
      return {
        ...state,
        stores: action.payload,
      };
    }

    case types.FETCH_STORES_BY_EMPLOYEE_SUCCESS: {
      const stores = checkLogoStores(action.payload);
      return {
        ...state,
        stores,
      };
    }

    case types.FETCH_STORES_NEARBY_SUCCESS: {
      return {
        ...state,
        stores: action.payload,
      };
    }

    case types.FETCH_STORE_PRODUCTS_SUCCESS: {
      return {
        ...state,
        storeProducts: action.payload,
      };
    }

    default:
      return { ...state };
  }
};

import Apis from 'apis';
import { createService } from 'utils/serviceFactory';

const StoreService = createService(Apis.stores, {
  getStores: {
    method: 'GET',
    url: `${Apis.stores}`,
  },
  getNearbyStores: {
    method: 'GET',
    url: `${Apis.stores}/nearBy`,
  },
});

export default StoreService;

class Reward {
  id;
  name;
  logo;
  
  constructor({
    id,
    name,
    logo,
    points,
    thumbnail,
  }) {
    this.id = id;
    this.name = name;
    this.logo = logo;
  }
}

export default Reward;

import { takeLatest } from 'redux-saga/effects';

import { doSagaActionGenerator } from 'utils/sagaHelpers';

// SERVICES
import MerchantsService from 'services/Merchants.service';
import StoresService from 'services/Stores.service';

// TYPES
import * as types from './Stores.actionTypes';

// FLOW
import {
  fetchStoreDetailFlow,
  fetchStoresNearByFlow,
  fetchStoreProductsFlow,
  fetchStoresFlow,
  fetchStoresForEmployeeFlow,
} from './Stores.actions';
import StoreService from 'services/Stores.service';

export function* fetchStores() {
  yield takeLatest(types.FETCH_STORES_REQUEST, action => {
    return doSagaActionGenerator({
      action,
      flow: fetchStoresFlow,
      apiService: StoresService.getStores,
    });
  });
}

export function* fetchStoresForEmployee() {
  yield takeLatest(types.FETCH_STORES_BY_EMPLOYEE_REQUEST, action => {
    return doSagaActionGenerator({
      action,
      flow: fetchStoresForEmployeeFlow,
      apiService: MerchantsService.getStores,
    });
  });
}

export function* storeDetail() {
  yield takeLatest(types.FETCH_STORE_DETAIL_REQUEST, action => {
    return doSagaActionGenerator({
      action,
      flow: fetchStoreDetailFlow,
      apiService: MerchantsService.getStore,
    });
  });
}

export function* storeNearBy() {
  yield takeLatest(types.FETCH_STORES_NEARBY_REQUEST, action =>
    doSagaActionGenerator({
      action: action,
      flow: fetchStoresNearByFlow,
      apiService: StoreService.getNearbyStores,
    })
  );
}

export function* storeProducts() {
  yield takeLatest(types.FETCH_STORE_PRODUCTS_REQUEST, action => {
    return doSagaActionGenerator({
      action,
      flow: fetchStoreProductsFlow,
      apiService: MerchantsService.getStoreProducts,
    });
  });
}

import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';

const StyledCard = styled.div`
  border-radius: ${props => props.theme.cardBorderRadius};
  box-shadow: ${props => props.theme.cardBoxShadow};
  background: ${props => (props.white ? '#fff' : 'none')};
`;

const Card = ({ children, className, ...rest }) => (
  <StyledCard className={`card ${className || ''}`} {...rest}>
    {children}
  </StyledCard>
);

Card.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.node,
    PropTypes.string,
  ]),
};

Card.defaultProps = {
  className: null,
  children: null,
};

export default withTheme(Card);

import * as types from './User.actionTypes';

// Fetch User information for employeeApp
export const fetchUserInformationFlow = {
  request: ({ queryParams, callback }) => ({
    type: types.FETCH_USER_INFORMATION_REQUEST,
    queryParams,
    callback,
  }),
  success: data => ({
    type: types.FETCH_USER_INFORMATION_SUCCESS,
    payload: data,
  }),
  failure: err => ({
    type: types.FETCH_USER_INFORMATION_FAILURE,
    payload: err,
  }),
};

export const fetchUserInformation = fetchUserInformationFlow.request;

export const THEME = {
  // FONTS
  fontPrimary: '"Montserrat", sans- serif',

  // FONT SIZE
  textDefault: '17px',
  textMedium: '15px',
  textSmall: '13px',

  // COLORS
  colorPrimary: '#363895',
  colorButtonDeactived: '#C8C8C8',
  colorLink: '#1D78ED',
  colorDanger: '#ff6357',
  colorOrange: '#FFBB10',

  colorText: '#344356',
  colorPlaceholder: 'rgba(#344356, 0.5)',
  colorBorder: '#C4C4C4',

  // BORDER RADIUS
  cardBorderRadius: '16px',
  buttonBorderRadius: '16px',
  inputBorderRadius: '16px',

  // PROGRESS BAR
  colorProgressBar: '#344356',
  colorNextStep: '#BAC0C7',
  colorActiveStep: '#FFBB10',

  // BOX SHADOWS
  cardBoxShadow: '0px 12px 19px rgba(108, 108, 108, 0.1)',
  cardBoxShadowSlight: '0px 2px 19px rgba(108, 108, 108, 0.1)',
  inputBoxShadow: '0px 12px 19px rgba(0, 0, 0, 0.0851449)',
  activeButtonBoxShadow: '0px 10px 25px rgba(54, 56, 149, 0.3)',
  primaryBoxShadow: '0px 5px 12.5px rgba(54, 56, 149, 0.3)',
  dangerBoxShadow: '0px 5px 12.5px rgba(#ff6357, 0.3)',
};

export default THEME;

import { createSelector } from 'reselect';

const storesSelector = () => state => state.stores;

// store details
export const storeDetailSelector = () =>
  createSelector(storesSelector(), stores => stores.store);

export const storeListSelector = () =>
  createSelector(storesSelector(), stores => stores.stores);

// stores near by
export const storesNearBySelector = () =>
  createSelector(storesSelector(), stores => stores.stores);

// stores products
export const storeProductsSelector = () =>
  createSelector(storesSelector(), stores => stores.storeProducts);

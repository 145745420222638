import { setToken } from 'utils/tokenHelper';

// Initial State
import { initialState } from './Auth.initialState';

// TYPES
import * as types from './Auth.actionTypes';

const generateProfileURL = ({ profilePhoto }) => {
  if (profilePhoto) {
    return `https${profilePhoto.split('http')[1]}`;
  }

  return '/assets/images/default_profile.png';
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_LOGIN_FORM_DETAILS: {
      return {
        ...state,
        login: {
          ...state.login,
          details: {
            ...state.login.details,
            ...action.payload.data,
          },
        },
      };
    }

    case types.UPDATE_LOGIN_STEP: {
      return {
        ...state,
        login: {
          ...state.login,
          activeStep: action.payload.stepIndex,
        },
      };
    }

    case types.SEND_LOGIN_VERIFICATION_CODE_SUCCESS: {
      const { accessToken } = action.payload;
      setToken(accessToken);
      return {
        ...state,
      };
    }

    case types.LOGIN_FAILURE:
      return { ...state };
    case types.LOGIN_SUCCESS: {
      const { accessToken } = action.payload;
      setToken(accessToken);
      return {
        ...state,
        login: {
          ...state.login,
          details: {
            ...state.login.details,
            loginSuccess: true,
          },
        },
      };
    }

    case types.LOGOUT_SUCCESS: {
      return {
        ...state,
        login: {
          details: {
            ...initialState.login.details,
            loginSuccess: false,
          },
          activeStep: 1,
        },
      };
    }

    case types.EDIT_USER_DETAILS: {
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          ...action.payload,
        },
      };
    }

    case types.FETCH_USER_DETAILS_SUCCESS: {
      return {
        ...state,
        userDetails: {
          ...action.payload,
          profilePhoto: generateProfileURL(action.payload),
        },
      };
    }

    case types.UPDATE_USER_DETAILS_SUCCESS: {
      return {
        ...state,
      };
    }

    case types.UPDATE_USER_PASSES_LINK_SUCCESS: {
      const s3link = action.payload.s3Link ? action.payload.s3Link : '';

      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          s3PassLink: `https${s3link.split('http')[1]}`,
        },
      };
    }

    default:
      return { ...state };
  }
};

import axios from 'axios';
import { get, pick } from 'lodash';
import { getToken } from 'utils/tokenHelper';
import { compile } from 'path-to-regexp';
import { API_URL } from 'configs';
import { push } from 'connected-react-router';

// ACTIONS
import { addNotification } from 'redux/GlobalUI/GlobalUI.actions';

const defaults = {
  cancellable: false,
  actions: {
    get: {
      method: 'GET',
    },
    create: {
      method: 'POST',
    },
    update: {
      method: 'PUT',
    },
    query: {
      method: 'GET',
    },
    remove: {
      method: 'DELETE',
    },
    delete: {
      method: 'DELETE',
    },
  },
};

export const inProgress = {};

export function createService(url, customActions) {
  const actions = { ...defaults.actions, ...customActions };

  function Resource() {}
  const keys = Object.keys(actions);
  keys.forEach(key => {
    const action = actions[key];
    const { isSafe } = action;
    const { CancelToken } = axios;
    let cancel;

    Resource[key] = (params, data) => {
      const toUrlWithParams = compile(action.url || url, {
        encode: encodeURIComponent,
      });
      if (!action.parallel && cancel !== undefined) {
        cancel('cancelled');
      }

      const queryParams = params
        ? Object.keys(params).filter(
            key => (action.url || url).indexOf(`:${key}`) === -1
          )
        : [];

      const headersFormData = action.isFormData
        ? { 'content-type': 'multipart/form-data' }
        : {};

      const axiosConfigs = {
        url: `${API_URL}${toUrlWithParams(params)}`,
        method: action.method,
        params: pick(params, queryParams),
        data,
        withCredentials: false,
        ...action.config,
        headers: {
          ...headersFormData,
          'Access-Control-Allow-Origin': '*',
        },
        cancelToken: new CancelToken(c => {
          cancel = c;
          if (!action.cannotBeCancelled) {
            inProgress[key] = c;
          }
        }),
      };
      if (!isSafe) {
        const accessToken = getToken();
        axiosConfigs.headers = {
          ...axiosConfigs.headers,
          Authorization: `Bearer ${accessToken}`,
        };
      }

      const promise = axios(axiosConfigs)
        .then(response => {
          delete inProgress[key];
          return response;
        })
        .catch(err => {
          if (typeof err === 'object' && err.message === 'cancelled') {
            return {
              status: 'cancelled',
            };
          }

          // TOOD: Should have a better way
          const { store } = window;
          const { dispatch } = store;

          const errorMessage =
            get(err, 'response.data.message', null) ||
            get(err, 'response.data.error', null);

          if (err.response.status === 401) {
            dispatch(push('/login'));
          }
          dispatch(
            addNotification({
              type: 'error',
              message:
                typeof errorMessage === 'string'
                  ? errorMessage
                  : 'There was an error',
            })
          );

          return { error: err };
        });
      return promise;
    };
  });
  return Resource;
}

export function createURLSearchParams(data) {
  const params = new URLSearchParams();
  Object.keys(data).forEach(key => {
    params.append(key, data[key]);
  });
  return params;
}

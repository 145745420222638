class Reward {
  id;
  name;
  source;
  points;
  thumbnail;
  url;

  constructor({
    id,
    name,
    source,
    points,
    thumbnail,
    url,
  }) {
    this.id =id;
    this.name = name;
    this.source = source;
    this.points = points;
    this.thumbnail = thumbnail;
    this.url = url;
  }
}

export default Reward;

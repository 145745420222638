import { takeLatest } from 'redux-saga/effects';
import { doSagaActionGenerator } from 'utils/sagaHelpers';
import * as types from './Transaction.actionTypes';
import TransactionService from 'services/Transaction.service';

// FLOW
import {
  fetchTransactionsFlow,
  fetchTransactionDetailsFlow,
  approveTransactionFlow,
  declineTransactionFlow,
  createTransactionFlow,
  fetchEmloyeeTransactionsFlow,
} from './Transaction.actions';

export function* createTransaction() {
  yield takeLatest(types.CREATE_TRANSACTION_REQUEST, action =>
    doSagaActionGenerator({
      action,
      flow: createTransactionFlow,
      apiService: TransactionService.createTransaction,
    })
  );
}

export function* fetchEmployeeTransactions() {
  yield takeLatest(types.FETCH_EMPLOYEE_TRANSACTIONS_REQUEST, action =>
    doSagaActionGenerator({
      action,
      flow: fetchEmloyeeTransactionsFlow,
      apiService: TransactionService.fetchTransactionEmployee,
    })
  );
}

export function* fetchTransactions() {
  yield takeLatest(types.FETCH_TRANSACTIONS_REQUEST, action =>
    doSagaActionGenerator({
      action,
      flow: fetchTransactionsFlow,
      apiService: TransactionService.fetchTransactions,
    })
  );
}

export function* fetchTransactionDetails() {
  yield takeLatest(types.FETCH_TRANSACTION_DETAILS_REQUEST, action =>
    doSagaActionGenerator({
      action,
      flow: fetchTransactionDetailsFlow,
      apiService: TransactionService.fetchTransactionDetails,
    })
  );
}

export function* approveTransaction() {
  yield takeLatest(types.APPROVE_TRANSACTION_REQUEST, action =>
    doSagaActionGenerator({
      action,
      flow: approveTransactionFlow,
      apiService: TransactionService.updateTransactionDetails,
    })
  );
}

export function* declineTransaction() {
  yield takeLatest(types.DECLINE_TRANSACTION_REQUEST, action =>
    doSagaActionGenerator({
      action,
      flow: declineTransactionFlow,
      apiService: TransactionService.updateTransactionDetails,
    })
  );
}

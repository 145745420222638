import { createSelector } from 'reselect';

import { TRANSACTION_STATUS } from 'constants/transactions';

const transactionSelector = () => state => state.transaction;
export const transactionsSelector = () =>
  createSelector(transactionSelector(), transaction => transaction.list);

export const employeePendingTransactionsSelector = () =>
  createSelector(transactionSelector(), transaction =>
    transaction.list.filter(
      item => item.transactionStatus === TRANSACTION_STATUS.PENDING
    )
  );

export const employeeNotPendingTransactionsSelector = () =>
  createSelector(transactionSelector(), transaction =>
    transaction.list.filter(
      item => item.transactionStatus !== TRANSACTION_STATUS.PENDING
    )
  );

export const transactionDetailsSelector = () =>
  createSelector(transactionSelector(), transaction => transaction.single);

export const paymentDetailsSelector = () =>
  createSelector(transactionSelector(), transaction => transaction.payment);

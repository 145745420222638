import * as types from './BankAccount.actionTypes';

export const fetchBankAccountsFlow = {
  request: callback => ({
    type: types.FETCH_BANK_ACCOUNTS_REQUEST,
    callback,
  }),
  success: data => ({
    type: types.FETCH_BANK_ACCOUNTS_SUCCESS,
    payload: data,
  }),
  failure: err => ({
    type: types.FETCH_BANK_ACCOUNTS_FAILURE,
    payload: err,
  }),
};
export const fetchBankAccounts = fetchBankAccountsFlow.request;

export const fetchBankAccountDetailsFlow = {
  request: ({ queryParams }) => ({
    type: types.FETCH_BANK_ACCOUNT_DETAILS_REQUEST,
    queryParams,
  }),
  success: data => ({
    type: types.FETCH_BANK_ACCOUNT_DETAILS_SUCCESS,
    payload: data,
  }),
  failure: err => ({
    type: types.FETCH_BANK_ACCOUNT_DETAILS_FAILURE,
    payload: err,
  }),
};
export const fetchBankAccountDetails = fetchBankAccountDetailsFlow.request;

export const setDefaultBankAccountForUserFlow = {
  request: ({ queryParams, params, callback }) => ({
    type: types.SET_DEFAULT_BANK_ACCOUNT_FOR_USER_REQUEST,
    queryParams,
    params,
    callback,
  }),
  success: data => ({
    type: types.SET_DEFAULT_BANK_ACCOUNT_FOR_USER_SUCCESS,
    payload: data,
  }),
  failure: err => ({
    type: types.SET_DEFAULT_BANK_ACCOUNT_FOR_USER_FAILURE,
    payload: err,
  }),
};
export const setDefaultBankAccountForUser =
  setDefaultBankAccountForUserFlow.request;

export const removeBankAccountFlow = {
  request: ({ queryParams, callback }) => ({
    type: types.REMOVE_BANK_ACCOUNT_REQUEST,
    queryParams,
    callback,
  }),
  success: data => ({
    type: types.REMOVE_BANK_ACCOUNT_SUCCESS,
    payload: data,
  }),
  failure: err => ({
    type: types.REMOVE_BANK_ACCOUNT_FAILURE,
    payload: err,
  }),
};
export const removeBankAccount = removeBankAccountFlow.request;

export const addBankAccountFlow = {
  request: action => ({
    type: types.ADD_BANK_ACCOUNT_REQUEST,
    ...action,
  }),
  success: data => ({
    type: types.ADD_BANK_ACCOUNT_SUCCESS,
    payload: data,
  }),
  failure: err => ({
    type: types.ADD_BANK_ACCOUNT_FAILURE,
    payload: err,
  }),
};
export const addBankAccount = addBankAccountFlow.request;

export const fetchAccountsFlow = {
  request: action => ({
    type: types.FETCH_ACCOUNTS_REQUEST,
    ...action,
  }),
  success: (data, meta) => ({
    type: types.FETCH_ACCOUNTS_SUCCESS,
    payload: data,
    meta,
  }),
  failure: err => ({
    type: types.FETCH_ACCOUNTS_FAILURE,
    payload: err,
  }),
};
export const fetchAccounts = fetchAccountsFlow.request;

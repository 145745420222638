export const FETCH_TRANSACTION_DETAILS_REQUEST =
  'FETCH_TRANSACTION_DETAILS_REQUEST';
export const FETCH_TRANSACTION_DETAILS_SUCCESS =
  'FETCH_TRANSACTION_DETAILS_SUCCESS';
export const FETCH_TRANSACTION_DETAILS_FAILURE =
  'FETCH_TRANSACTION_DETAILS_FAILURE';

export const RESET_TRANSACTION_DETAILS = 'RESET_TRANSACTION_DETAILS';

export const APPROVE_TRANSACTION_REQUEST = 'APPROVE_TRANSACTION_REQUEST';
export const APPROVE_TRANSACTION_SUCCESS = 'APPROVE_TRANSACTION_SUCCESS';
export const APPROVE_TRANSACTION_FAILURE = 'APPROVE_TRANSACTION_FAILURE';

export const DECLINE_TRANSACTION_REQUEST = 'DECLINE_TRANSACTION_REQUEST';
export const DECLINE_TRANSACTION_SUCCESS = 'DECLINE_TRANSACTION_SUCCESS';
export const DECLINE_TRANSACTION_FAILURE = 'DECLINE_TRANSACTION_FAILURE';

export const UPDATE_PAYMENT_DETAILS = 'UPDATE_PAYMENT_DETAILS';

export const FETCH_TRANSACTIONS_REQUEST = 'FETCH_TRANSACTIONS_REQUEST';
export const FETCH_TRANSACTIONS_SUCCESS = 'FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_FAILURE = 'FETCH_TRANSACTIONS_FAILURE';

export const CREATE_TRANSACTION_REQUEST = 'CREATE_TRANSACTION_REQUEST';
export const CREATE_TRANSACTION_SUCCESS = 'CREATE_TRANSACTION_SUCCESS';
export const CREATE_TRANSACTION_FAILURE = 'CREATE_TRANSACTION_FAILURE';

export const FETCH_EMPLOYEE_TRANSACTIONS_REQUEST =
  'FETCH_EMPLOYEE_TRANSACTIONS_REQUEST';
export const FETCH_EMPLOYEE_TRANSACTIONS_SUCCESS =
  'FETCH_EMPLOYEE_TRANSACTIONS_SUCCESS';
export const FETCH_EMPLOYEE_TRANSACTIONS_FAILURE =
  'FETCH_EMPLOYEE_TRANSACTIONS_FAILURE';

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

// ICON
import Loading from 'components/Loading/Loading';

const ButtonBasic = styled.button`
  background: none;
  height: ${props => (props.size === 'small' ? '29px' : '58px')};
  line-height: ${props => (props.size === 'small' ? '22px' : '54px')};
  text-align: center;
  font-size: ${props => (props.size === 'small' ? '13px' : '17px')};
  font-weight: bold;
  display: ${props => (props.block ? 'block' : 'inline-block')};
  width: ${props => (props.block ? '100%' : null)};
  border-radius: ${props => props.theme.buttonBorderRadius};
  border: 2px solid #404e61;
  padding-left: 12px;
  padding-right: 12px;
  text-decoration: none;
  position: relative;

  & + button {
    margin-top: 24px;
  }

  &:disabled {
    background-color: ${props => props.theme.colorButtonDeactived};
    color: #fff;
    border-color: ${props => props.theme.colorButtonDeactived};
  }

  .button-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px !important;

    & > * {
      z-index: 2;
      position: relative;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0.4;
      background-color: #0f117a;
      border-radius: 50%;
    }

    &.left-icon {
      left: 16px;
    }
    &.right-icon {
      right: 16px;
    }
  }
`;

const ButtonPrimary = styled(ButtonBasic)`
  background-color: ${props => props.theme.colorPrimary};
  color: #fff;
  box-shadow: ${props => props.theme.activeButtonBoxShadow};
  border-color: ${props => props.theme.colorPrimary};

  &:disabled {
    background-color: ${props => props.theme.colorButtonDeactived};
    color: #fff;
    border-color: ${props => props.theme.colorButtonDeactived};
    box-shadow: none;
  }
`;

const ButtonSecondary = styled(ButtonBasic)`
  color: ${props => props.theme.colorLink};
  border-color: #e9f2fe;
  background-color: #e9f2fe;
  font-weight: 600;
`;

export const OutlineButton = styled(ButtonBasic)`
  outline: none;
  border: none;
  background: rgba(255, 187, 16, 0.1);
  color: ${props => props.theme.colorOrange};
`;

const Button = ({
  children,
  loading,
  disabled,
  link,
  primary,
  secondary,
  leftIcon,
  rightIcon,
  to,
  onClick,
  ...rest
}) => {
  let buttonContent;

  const history = useHistory();
  const handleClick = () => {
    if (onClick) {
      return onClick();
    }

    if (to) {
      return history.push(to);
    }

    return;
  };

  if (loading) {
    buttonContent = <Loading />;
  } else {
    const leftIconElem = leftIcon ? (
      <span className="button-icon left-icon">{leftIcon}</span>
    ) : null;
    const rightIconElem = rightIcon ? (
      <span className="button-icon right-icon">{rightIcon}</span>
    ) : null;
    buttonContent = (
      <>
        {leftIconElem}
        {children}
        {rightIconElem}
      </>
    );
  }

  if (primary) {
    return (
      <ButtonPrimary
        disabled={disabled || loading}
        {...rest}
        onClick={handleClick}
      >
        {buttonContent}
      </ButtonPrimary>
    );
  }

  if (secondary) {
    return (
      <ButtonSecondary
        disabled={disabled || loading}
        {...rest}
        onClick={handleClick}
      >
        {buttonContent}
      </ButtonSecondary>
    );
  }

  return (
    <ButtonBasic disabled={disabled || loading} {...rest} onClick={handleClick}>
      {buttonContent}
    </ButtonBasic>
  );
};

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.node,
    PropTypes.string,
  ]),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  link: PropTypes.bool,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  block: PropTypes.bool,
};

Button.defaultProps = {
  children: null,
  leftIcon: null,
  rightIcon: null,
  loading: false,
  disabled: false,
  link: false,
  primary: false,
  secondary: false,
  block: false,
};

export default Button;

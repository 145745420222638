import React, { lazy } from 'react';
import { Route } from 'react-router-dom';

// COMPONENTS
import AuthenticatedRoute from 'components/AuthenticatedRoute/AuthenticatedRoute';

// ROUTES
import { MerchantRoutes } from 'routes';
import PageTransactionDetails from 'pages/PageTransactionDetails/PageTransactionDetails';

// PAGES
const PageQrModalEmployee = lazy(() =>
  import('pages/PageQrModal/PageQrModal.Employee')
);
const PageQrModalCustomer = lazy(() =>
  import('pages/PageQrModal/PageQrModal.Customer')
);
const PageDashboard = lazy(() => import('pages/PageDashboard/PageDashboard'));
const PageTransactionHistory = lazy(() =>
  import('pages/PageTransactionHistory/PageTransactionHistory')
);
const PageQrCode = lazy(() => import('pages/PageQrCode/PageQrCode'));
const PagePay = lazy(() => import('pages/PagePay/PagePay'));
const PageProfile = lazy(() => import('pages/PageProfile/PageProfile'));
const PageLinkBankAccount = lazy(() =>
  import('pages/PageLinkBankAccount/PageLinkBankAccount')
);
const PageCardDetails = lazy(() =>
  import('pages/PageCardDetails/PageCardDetails')
);

const PageEmployeeStoresList = lazy(() =>
  import('pages/Employee/PageStoresList/PageStoresList')
);

const PageEmployeeDashboard = lazy(() =>
  import('pages/Employee/PageDashboard/PageDashboard')
);

export const ProtectedRoute = () => {
  return (
    <AuthenticatedRoute>
      <Route exact path="/link-bank-account" component={PageLinkBankAccount} />
      <Route exact path="/dashboard" component={PageDashboard} />
      <Route exact path="/transactions" component={PageTransactionHistory} />
      <Route
        exact
        path="/transactions/:transactionID"
        component={PageTransactionDetails}
      />
      <Route exact path="/my-qrcode" component={PageQrCode} />
      <Route exact path="/qr-modal" component={PageQrModalCustomer} />
      <Route
        exact
        path="/profile"
        render={({ history }) => <PageProfile history={history} />}
      />
      <Route path="/pay" component={PagePay} />
      <Route path="/merchants" component={MerchantRoutes} />
      <Route path="/cards/:cardId" component={PageCardDetails} />
      <Route path="/employee/stores" component={PageEmployeeStoresList} />
      <Route
        exact
        path="/employee/:storeId/dashboard"
        render={({ history, match }) => (
          <PageEmployeeDashboard history={history} match={match} />
        )}
      />
      <Route
        path="/employee/profile"
        render={({ history }) => (
          <PageProfile history={history} isEmployee={true} />
        )}
      />
      <Route
        exact
        path="/employee/:storeId/qr-modal"
        render={({ history, match }) => (
          <PageQrModalEmployee history={history} match={match} />
        )}
      />
    </AuthenticatedRoute>
  );
};

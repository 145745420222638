export const initialState = {
  single: null,
  payment: {
    tip: 0,
    amount: 0,
    amountTotal: 0,
    tax: 0,
    fee: 0,
  },
  fromCreation: {},
  list: [],
};

import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

// HELPERS
import { doSagaActionGenerator } from 'utils/sagaHelpers';

// SERVICES
import AuthenticationService from 'services/Auth.service';
import UserServices from 'services/User.service';

// HELPERS
import { toSavingUSPhoneNumber } from 'utils/number';

// ACTION TYPES
import * as types from './Auth.actionTypes';

// UTIL
import { deleteToken } from 'utils/tokenHelper';

// FLOW
import {
  loginFlow,
  getUserDetailsFlow,
  updateUserDetailsFlow,
  sendLoginVerificationCodeFlow,
  updateUserPassesFlow,
  logoutFlow,
} from './Auth.actions';

export function* sendLoginVerificationCode() {
  return yield takeLatest(
    types.SEND_LOGIN_VERIFICATION_CODE_REQUEST,
    action => {
      if (action.isUsingPhone) {
        return doSagaActionGenerator({
          action: {
            ...action,
            params: {
              ...action.params,
              phoneNumber: toSavingUSPhoneNumber(action.params.phoneNumber),
            },
          },
          flow: sendLoginVerificationCodeFlow,
          apiService: AuthenticationService.sendLoginVerificationCode,
        });
      }

      return doSagaActionGenerator({
        action: {
          ...action,
          params: {
            ...action.params,
          },
        },
        flow: sendLoginVerificationCodeFlow,
        apiService: AuthenticationService.sendLoginVerificationCodeUsingEmail,
      });
    }
  );
}

export function* login() {
  yield takeLatest(types.LOGIN_REQUEST, action => {
    if (action.phoneLogin) {
      return doSagaActionGenerator({
        action: {
          ...action,
          params: {
            loginType: 'PHONE',
            code: action.params.pin.join(''),
          },
        },
        flow: loginFlow,
        apiService: AuthenticationService.login,
      });
    }

    return doSagaActionGenerator({
      action: {
        ...action,
        params: {
          loginType: 'EMAIL', // Will Change Later
          code: action.params.pin.join(''),
        },
      },
      flow: loginFlow,
      apiService: AuthenticationService.login,
    });
  });
}

export function* getUserDetails() {
  yield takeLatest(types.FETCH_USER_DETAILS_REQUEST, action =>
    doSagaActionGenerator({
      action,
      flow: getUserDetailsFlow,
      apiService: AuthenticationService.getUserDetails,
    })
  );
}

export function* updateUserDetails() {
  yield takeLatest(types.UPDATE_USER_DETAILS_REQUEST, action =>
    doSagaActionGenerator({
      action,
      flow: updateUserDetailsFlow,
      apiService: AuthenticationService.updateUserDetails,
    })
  );
}

export function* updateUserPasses() {
  yield takeLatest(types.UPDATE_USER_PASSES_LINK_REQUEST, action =>
    doSagaActionGenerator({
      action,
      flow: updateUserPassesFlow,
      apiService: UserServices.createPasses,
    })
  );
}

function* handleLogout() {
  deleteToken();
  yield put(logoutFlow.success());
  yield put(push('/'));
}

export function* logout() {
  yield takeLatest(types.LOGOUT_REQUEST, handleLogout);
}

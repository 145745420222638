import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledToggle = styled.label`
  position: relative;
  span {
    opacity: ${props => (props.disabled ? 0.5 : 1)};
    display: block;
    position: relative;
    width: 46px;
    height: 23px;
    background-color: #c8c8c8;
    border-radius: 23px;
    transition: 0.4s all;
    &::before {
      content: '';
      position: absolute;
      background-color: #fff;
      width: 21px;
      height: 21px;
      top: 1px;
      left: 1px;
      border-radius: 50%;
      transition: 0.4s all;
    }
  }
  input {
    opacity: 0;
    height: 0;
    width: 0;
    position: absolute;
    &:checked {
      + span {
        background-color: ${props => props.theme.colorPrimary};
        &::before {
          left: 24px;
        }
      }
    }
  }
`;

const Toggle = ({ onToggle, defaultChecked, disabled }) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(defaultChecked || false);
  }, [defaultChecked]);

  const doToggle = () => {
    setChecked(!checked);
    if (typeof onToggle === 'function') {
      onToggle();
    }
  };

  return (
    <StyledToggle disabled={disabled}>
      <input
        type="checkbox"
        onChange={doToggle}
        checked={checked}
        disabled={disabled}
      />
      <span />
    </StyledToggle>
  );
};

Toggle.propTypes = {
  onToggle: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
};
Toggle.defaultProps = {
  checked: false,
  disabled: false,
};
export default Toggle;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

// ACTIONS
import { fetchTransactionDetails } from 'redux/Transaction/Transaction.actions';

// SELECTORS
import { transactionDetailsSelector } from 'redux/Transaction/Transaction.selectors';
import { userBankAccountsSelector } from 'redux/BankAccount/BankAccount.selectors';

// HELPERS
import { loadingGenerator } from 'utils/loadingHelpers';
import { toCurrency, calculateAmountTotal } from 'utils/number';

// COMPONENTS
import { Loading } from 'components/common';
import Navigation from 'components/Navigation/Navigation';
import { Badge, Container } from 'components/common';

// ICONS
import { FiX, FiCheck, FiChevronRight } from 'react-icons/fi';
import moment from 'moment';
import { TRANSACTION_STATUS } from 'constants/transactions';

const StyledLogo = styled.div`
  position: relative;
  background: #ffffff;
  box-shadow: 0px 12px 19px rgba(108, 108, 108, 0.1);
  width: 84px;
  height: 84px;
  img {
    border-radius: 16px;
    overflow: hidden;
  }
`;

const StyledRow = styled.div`
  font-size: 13px;
  span {
    white-space: nowrap;
    margin-right: 20px;
  }
  b,
  strong {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
  }
  b {
    font-weight: 600;
  }
`;
const PageTransactionDetails = ({ transaction }) => {
  const {
    createdAt,
    tip,
    fee,
    amount,
    tax,
    bankID,
    storeID,
    transactionID,
    transactionStatus,
    points,
  } = transaction;

  const bankAccounts = useSelector(userBankAccountsSelector());
  const selectedBank =
    bankID && bankAccounts.find(item => item.itemID === bankID.itemID);
  // const { logo, name } = storeID;
  const logo = storeID && storeID.logo;
  const name = storeID && storeID.name;

  return (
    <div>
      <div className="flex justify-content-center mb-6">
        <StyledLogo>
          {logo ? <img src={logo} /> : null}
          {transactionStatus === TRANSACTION_STATUS.DENIED ? (
            <Badge type="danger">
              <FiX />
            </Badge>
          ) : null}
          {transactionStatus === TRANSACTION_STATUS.ACCEPTED ? (
            <Badge type="success">
              <FiCheck />
            </Badge>
          ) : null}
        </StyledLogo>
      </div>
      {name ? (
        <div className="text-center text--semibold mb-9">{name}</div>
      ) : null}
      {selectedBank ? (
        <React.Fragment>
          <StyledRow className="flex justify-content-space-between">
            <span>Bank Account</span>
            <b>
              {selectedBank.itemName}
              {selectedBank.defaultAccountID ? (
                <b>&nbsp;-&nbsp;{selectedBank.defaultAccountID.mask}</b>
              ) : null}
            </b>
          </StyledRow>
          <hr className="mt-3 mb-3" />
        </React.Fragment>
      ) : null}
      <StyledRow className="flex justify-content-space-between">
        <span>Transaction ID</span>
        <b>{transactionID}</b>
      </StyledRow>
      <hr className="mt-3 mb-3" />
      <StyledRow className="flex justify-content-space-between">
        <span>Date</span>
        {createdAt ? (
          <b>{moment(createdAt).format('MMM DD, YYYY hh:mm A')}</b>
        ) : null}
      </StyledRow>
      <hr className="mt-3 mb-3" />
      <StyledRow className="flex justify-content-space-between">
        <span>Total</span>
        <b>{toCurrency(amount)}</b>
      </StyledRow>
      <hr className="mt-3 mb-3" />
      {points ? (
        <React.Fragment>
          <StyledRow className="flex justify-content-space-between">
            <span>Points Earned</span>
            <b className="text--secondary flex align-items-center">
              <img src="/assets/images/icons/points-orange.svg" alt="" />
              {points}
            </b>
          </StyledRow>
          <hr className="mt-3 mb-3" />
        </React.Fragment>
      ) : null}
      <StyledRow className="mt-6 mb-3">
        <strong>Need Help?</strong>
      </StyledRow>
      <StyledRow className="flex justify-content-space-between">
        <span>Contact Us</span>
        <b>
          <FiChevronRight style={{ fontSize: '20px', opacity: 0.3 }} />
        </b>
      </StyledRow>
    </div>
  );
};

PageTransactionDetails.propTypes = {
  transaction: PropTypes.object.isRequired,
};
export default PageTransactionDetails;

import { call, put, takeLatest } from 'redux-saga/effects';

// SERVICES
import AuthenticationService from 'services/Auth.service';

// HELPERS
import { doSagaActionGenerator } from 'utils/sagaHelpers';

// ACTION TYPES
import { toSavingUSPhoneNumber } from 'utils/number';
import * as types from './Register.actionTypes';

// FLOW
import {
  createUserDetailsFlow,
  fetchRegisteringUserDetailsFlow,
  sendVerifyCodeFlow,
  verifyPhoneNumberFlow,
  setPinCodeFlow,
  linkBankAccountFlow,
  setDefaultAccountFlow,
} from './Register.actions';

export function* createUserDetails() {
  yield takeLatest(types.CREATE_USER_DETAILS_REQUEST, action =>
    doSagaActionGenerator({
      action,
      flow: createUserDetailsFlow,
      apiService: AuthenticationService.createUserDetails,
    })
  );
}

function* doFetchRegisteringUserDetails() {
  const response = yield call(AuthenticationService.createUserDetails, null);
  if (response.error) {
    yield put(fetchRegisteringUserDetailsFlow.failure(response));
    return;
  }
  const { data } = response;
  if (!data) {
    yield put(fetchRegisteringUserDetailsFlow.failure(null));
    return;
  }
  yield put(fetchRegisteringUserDetailsFlow.success(data));
}

export function* fetchRegisteringUserDetails() {
  yield takeLatest(
    types.FETCH_REGISTERING_USER_DETAILS_REQUEST,
    doFetchRegisteringUserDetails
  );
}

export function* sendVerifyCode() {
  yield takeLatest(types.SEND_VERIFY_CODE_REQUEST, action =>
    doSagaActionGenerator({
      action: {
        ...action,
        params: {
          ...action.params,
          action: 'SIGNUP',
          phoneNumber: toSavingUSPhoneNumber(action.params.phoneNumber),
        },
      },
      flow: sendVerifyCodeFlow,
      apiService: AuthenticationService.sendVerifyCode,
    })
  );
}

// function* doVefiryPhoneNumber(action) {
//   const {
//     params: { phoneNumber, code },
//   } = action;
//   const response = yield call(AuthenticationService.verifyPhoneNumber, null, {
//     phoneNumber,
//     code,
//   });
//   if (response.error) {
//     yield put(verifyPhoneNumberFlow.failure(response));
//     return;
//   }
//   const { data } = response;
//   if (!data) {
//     yield put(verifyPhoneNumberFlow.failure(null));
//     return;
//   }
//   yield put(verifyPhoneNumberFlow.success(data));
// }

export function* vefiryPhoneNumber() {
  yield takeLatest(types.VERIFY_PHONE_NUMBER_REQUEST, action =>
    doSagaActionGenerator({
      action: {
        ...action,
        params: {
          ...action.params,
          phoneNumber: toSavingUSPhoneNumber(action.params.phoneNumber),
        },
      },
      flow: verifyPhoneNumberFlow,
      apiService: AuthenticationService.verifyPhoneNumber,
    })
  );
}

export function* linkBankAccount() {
  yield takeLatest(types.LINK_BANK_ACCOUNT_REQUEST, action =>
    doSagaActionGenerator({
      action,
      flow: linkBankAccountFlow,
      apiService: AuthenticationService.linkBankAccount,
    })
  );
}

export function* setDefaultAccount() {
  yield takeLatest(types.SET_REGISTERING_DEFAULT_ACCOUNT_REQUEST, action =>
    doSagaActionGenerator({
      action,
      flow: setDefaultAccountFlow,
      apiService: AuthenticationService.setRegisterDefaultAccount,
    })
  );
}

export function* setPinCode() {
  yield takeLatest(types.SET_PIN_CODE_REQUEST, action =>
    doSagaActionGenerator({
      action,
      flow: setPinCodeFlow,
      apiService: AuthenticationService.setPinCode,
    })
  );
}

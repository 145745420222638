import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { AiOutlineSearch } from 'react-icons/ai';

const StyledInput = styled.input`
  height: ${props => (props.size === 'small' ? '40px' : '60px')};
  width: 100%;
  background-color: ${props => props.background || '#f3f3f3'};
  border: 1px solid;
  border-color: ${props =>
    props.background === 'none' ? '#E3E3E3' : 'transparent'};
  box-shadow: none;
  border-radius: ${props => (props.size === 'small' ? '8px' : '16px')};
  font-size: 15px;
  padding-left: 33px;
  &::placeholder {
    opacity: 0.5;
  }
`;

const StyledIcon = styled.i`
  position: absolute;
  ${props => (props.iconAlignment === 'left' ? 'left' : 'right')}: 12px;
  font-size: ${props => (props.size === 'small' ? '15px' : '20px')};
  top: 0;
  bottom: 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  font-size: 15px;
  opacity: ${props => (props.size === 'small' ? '0.5' : '1')};
  font-size: ${props => (props.size === 'small' ? '15px' : '32px')};
`;

const SearchInput = ({
  placeholder,
  onChange,
  iconAlignment,
  size,
  background,
  style,
  className,
  handleClickSearchIcon,
  value,
}) => {
  const [valueChild, setValueChild] = useState(value);

  if (value !== valueChild) {
    setValueChild(value);
  }

  const onValueChange = e => {
    setValueChild(e.target.value);
    if (typeof onChange === 'function') {
      onChange(e.target.value);
    }
  };

  return (
    <label
      style={{ ...style, position: 'relative', display: 'block' }}
      className={className}
    >
      <StyledIcon iconAlignment={iconAlignment} size={size}>
        <AiOutlineSearch onClick={handleClickSearchIcon} />
      </StyledIcon>
      <StyledInput
        value={valueChild}
        background={background}
        placeholder={placeholder || 'Search'}
        onChange={onValueChange}
        size={size}
      />
    </label>
  );
};
SearchInput.propTypes = {
  onChange: PropTypes.func,
  iconAlignment: PropTypes.string,
  size: PropTypes.string,
  placeholder: PropTypes.string,
  background: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  style: PropTypes.object,
};
SearchInput.defaultProps = {
  onChange: null,
  iconAlignment: 'left',
  size: 'small',
  placeholder: null,
  background: null,
  style: null,
};

export default SearchInput;

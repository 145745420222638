export const UPDATE_REGISTER_FORM_DETAILS = 'UPDATE_REGISTER_FORM_DETAILS';
export const UPDATE_REGISTER_STEP = 'UPDATE_REGISTER_STEP';

export const CREATE_USER_DETAILS_REQUEST = 'CREATE_USER_DETAILS_REQUEST';
export const CREATE_USER_DETAILS_SUCCESS = 'CREATE_USER_DETAILS_SUCCESS';
export const CREATE_USER_DETAILS_FAILURE = 'CREATE_USER_DETAILS_FAILURE';

export const FETCH_REGISTERING_USER_DETAILS_REQUEST =
  'FETCH_REGISTERING_USER_DETAILS_REQUEST';
export const FETCH_REGISTERING_USER_DETAILS_SUCCESS =
  'FETCH_REGISTERING_USER_DETAILS_SUCCESS';
export const FETCH_REGISTERING_USER_DETAILS_FAILURE =
  'FETCH_REGISTERING_USER_DETAILS_FAILURE';

export const VERIFY_PHONE_NUMBER_REQUEST = 'VERIFY_PHONE_NUMBER_REQUEST';
export const VERIFY_PHONE_NUMBER_SUCCESS = 'VERIFY_PHONE_NUMBER_SUCCESS';
export const VERIFY_PHONE_NUMBER_FAILURE = 'VERIFY_PHONE_NUMBER_FAILURE';

export const SEND_VERIFY_CODE_REQUEST = 'SEND_VERIFY_CODE_REQUEST';
export const SEND_VERIFY_CODE_SUCCESS = 'SEND_VERIFY_CODE_SUCCESS';
export const SEND_VERIFY_CODE_FAILURE = 'SEND_VERIFY_CODE_FAILURE';

export const SET_PIN_CODE_REQUEST = 'SET_PIN_CODE_REQUEST';
export const SET_PIN_CODE_SUCCESS = 'SET_PIN_CODE_SUCCESS';
export const SET_PIN_CODE_FAILURE = 'SET_PIN_CODE_FAILURE';

export const LINK_BANK_ACCOUNT_REQUEST = 'LINK_BANK_ACCOUNT_REQUEST';
export const LINK_BANK_ACCOUNT_SUCCESS = 'LINK_BANK_ACCOUNT_SUCCESS';
export const LINK_BANK_ACCOUNT_FAILURE = 'LINK_BANK_ACCOUNT_FAILURE';

export const SET_REGISTERING_DEFAULT_ACCOUNT_REQUEST =
  'SET_REGISTERING_DEFAULT_ACCOUNT_REQUEST';
export const SET_REGISTERING_DEFAULT_ACCOUNT_SUCCESS =
  'SET_REGISTERING_DEFAULT_ACCOUNT_SUCCESS';
export const SET_REGISTERING_DEFAULT_ACCOUNT_FAILURE =
  'SET_REGISTERING_DEFAULT_ACCOUNT_FAILURE';

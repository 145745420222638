export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const UPDATE_LOGIN_FORM_DETAILS = 'UPDATE_LOGIN_FORM_DETAILS';
export const UPDATE_LOGIN_STEP = 'UPDATE_LOGIN_STEP';

export const FETCH_USER_DETAILS_REQUEST = 'FETCH_USER_DETAILS_REQUEST';
export const FETCH_USER_DETAILS_SUCCESS = 'FETCH_USER_DETAILS_SUCCESS';
export const FETCH_USER_DETAILS_FAILURE = 'FETCH_USER_DETAILS_FAILURE';

export const EDIT_USER_DETAILS = 'EDIT_USER_DETAILS';
export const UPDATE_USER_DETAILS_REQUEST = 'UPDATE_USER_DETAILS_REQUEST';
export const UPDATE_USER_DETAILS_SUCCESS = 'UPDATE_USER_DETAILS_SUCCESS';
export const UPDATE_USER_DETAILS_FAILURE = 'UPDATE_USER_DETAILS_FAILURE';

export const SEND_LOGIN_VERIFICATION_CODE_REQUEST =
  'SEND_LOGIN_VERIFICATION_CODE_REQUEST';
export const SEND_LOGIN_VERIFICATION_CODE_SUCCESS =
  'SEND_LOGIN_VERIFICATION_CODE_SUCCESS';
export const SEND_LOGIN_VERIFICATION_CODE_FAILURE =
  'SEND_LOGIN_VERIFICATION_CODE_FAILURE';

export const UPDATE_USER_PASSES_LINK_REQUEST =
  'UPDATE_USER_PASSES_LINK_REQUEST';
export const UPDATE_USER_PASSES_LINK_SUCCESS =
  'UPDATE_USER_PASSES_LINK_SUCCESS';
export const UPDATE_USER_PASSES_LINK_FAILURE =
  'UPDATE_USER_PASSES_LINK_FAILURE';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';

// ACTIONS
import {
  fetchTransactionDetails,
  resetTransacionDetails,
} from 'redux/Transaction/Transaction.actions';

// SELECTORS
import { transactionDetailsSelector } from 'redux/Transaction/Transaction.selectors';

// HELPERS
import { loadingGenerator } from 'utils/loadingHelpers';

// COMPONENTS
import Loading from 'components/Loading/Loading';
import Navigation from 'components/Navigation/Navigation';
import { Container } from 'components/common';
import TransactionDetails from 'components/TransactionDetails/TransactionDetails';

// ICONS
import { FiX } from 'react-icons/fi';

const PageTransactionDetails = () => {
  const history = useHistory();
  const { transactionID } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      fetchTransactionDetails({
        queryParams: {
          transactionID,
        },
      })
    );
    return () => {
      dispatch(resetTransacionDetails());
    };
  }, []);
  const loading = loadingGenerator(['FETCH_TRANSACTION_DETAILS'])();
  const transaction = useSelector(transactionDetailsSelector());
  return (
    <div
      id="pageTransactionDetails"
      style={{ background: '#fff', minHeight: '100vh' }}
    >
      <Navigation
        leftElem={<FiX onClick={() => history.push('/transactions')} />}
        label="Transaction Details"
      />
      <Container>
        {loading ? <Loading type="dots" /> : null}
        {!loading && transaction ? (
          <TransactionDetails transaction={transaction} />
        ) : null}
      </Container>
    </div>
  );
};

export default PageTransactionDetails;

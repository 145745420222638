import { call, put } from 'redux-saga/effects';

export function* doSagaActionGenerator({ action, flow, apiService }) {
  const response = yield call(
    apiService,
    { ...action.queryParams },
    {
      ...action.params,
    }
  );

  const { data } = response;

  if (!data || response.error) {
    yield put(flow.failure(response.error || null));
    return;
  }

  yield put(flow.success(data, { ...action.queryParams, ...action.params }));

  if (typeof action.callback === 'function') {
    action.callback({ ...data, meta: action.params });
  }
}

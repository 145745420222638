import * as types from './Stores.actionTypes';

export const fetchStoresFlow = {
  request: () => ({
    type: types.FETCH_STORES_REQUEST,
  }),
  success: data => ({
    type: types.FETCH_STORES_SUCCESS,
    payload: data.list,
  }),
  failure: err => ({
    type: types.FETCH_STORES_FAILURE,
    payload: err,
  }),
};

export const fetchStores = fetchStoresFlow.request;

export const fetchStoresForEmployeeFlow = {
  request: ({ queryParams }) => ({
    type: types.FETCH_STORES_BY_EMPLOYEE_REQUEST,
    queryParams,
  }),
  success: data => ({
    type: types.FETCH_STORES_BY_EMPLOYEE_SUCCESS,
    payload: data.list,
  }),
  failure: err => ({
    type: types.FETCH_STORES_BY_EMPLOYEE_FAILURE,
    payload: err,
  }),
};

export const fetchStoresForEmployee = fetchStoresForEmployeeFlow.request;

export const fetchStoreDetailFlow = {
  request: ({ queryParams }) => ({
    type: types.FETCH_STORE_DETAIL_REQUEST,
    queryParams,
  }),
  success: data => ({
    type: types.FETCH_STORE_DETAIL_SUCCESS,
    payload: data,
  }),
  failure: err => ({
    type: types.FETCH_STORE_DETAIL_FAILURE,
    payload: err,
  }),
};

export const fetchStoreDetail = fetchStoreDetailFlow.request;

export const fetchStoresNearByFlow = {
  request: ({ queryParams }) => ({
    type: types.FETCH_STORES_NEARBY_REQUEST,
    queryParams,
  }),
  success: data => ({
    type: types.FETCH_STORES_NEARBY_SUCCESS,
    payload: data.list,
  }),
  failure: err => ({
    type: types.FETCH_STORES_NEARBY_FAILURE,
    payload: err,
  }),
};

export const fetchStoresNearBy = fetchStoresNearByFlow.request;

export const fetchStoreProductsFlow = {
  request: ({ queryParams }) => ({
    type: types.FETCH_STORE_PRODUCTS_REQUEST,
    queryParams,
  }),
  success: data => ({
    type: types.FETCH_STORE_PRODUCTS_SUCCESS,
    payload: data,
  }),
  failure: err => ({
    type: types.FETCH_STORE_PRODUCTS_FAILURE,
    payload: err,
  }),
};

export const fetchStoreProducts = fetchStoreProductsFlow.request;

export const EVENTS = {
  REQUEST_TO_PAY: 'REQUEST_TO_PAY',
  DENIED_TRANSACTION: 'DENIED_TRANSACTION',
  ACCEPTED_TRANSACTION: 'ACCEPTED_TRANSACTION',
  TRANSACTION_PENDING: 'TRANSACTION_PENDING',
  TRANSACTION_CANCELED: 'TRANSACTION_CANCELED',
  TRANSACTION_PROCESSING: 'TRANSACTION_PROCESSING',
  TRANSACTION_SENT: 'TRANSACTION_SENT',
  TRANSACTION_RETURNED: 'TRANSACTION_RETURNED',
  FETCH_EMPLOYEE_TRANSACTIONS: 'FETCH_EMPLOYEE_TRANSACTIONS',
};

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledLabel = styled.label`
  font-weight: 500;
  font-size: 15px;
  line-height: 160%;
  letter-spacing: -0.24px;
  display: flex;

  & > input[type='radio'] {
    width: 0;
    height: 0;
    visibility: hidden;
    opacity: 0;
  }

  & > span {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid ${props => props.theme.colorPrimary};
    margin-right: 10px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      top: 3px;
      left: 3px;
      transform: scale(0);
      transition: 0.4s all;
      border-radius: 50%;
      background-color: ${props => props.theme.colorPrimary};
    }
  }
  input:checked ~ span {
    &::before {
      transform: scale(1);
    }
  }
`;

const Radio = ({ checked, onClick, label, className }) => {
  return (
    <StyledLabel onClick={onClick} className={className}>
      <input type="radio" checked={checked} onChange={() => {}} />
      <span />
      {label}
    </StyledLabel>
  );
};

Radio.propTypes = {
  checked: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
};
Radio.defaultProps = {
  checked: false,
  label: null,
  className: null,
};

export default Radio;

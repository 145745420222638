import Reward from 'models/Reward.model';

import { SOURCES } from './sources';

export const REWARDS = [
  new Reward({
    id: 1,
    source: SOURCES[0],
    name: 'Free $10 Vouchers',
    points: 1245,
    thumbnail: '/assets/images/reward-placeholder.svg',
  }),
  new Reward({
    id: 2,
    source: SOURCES[0],
    name: 'Discount 20% on total bill',
    points: 3000,
    thumbnail: '/assets/images/reward-placeholder.svg',
  }),
  new Reward({
    id: 3,
    source: SOURCES[0],
    name: 'Free $20 Vouchers',
    points: 2050,
    thumbnail: '/assets/images/reward-placeholder.svg',
  }),
];

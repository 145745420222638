import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styled from 'styled-components';

const StyledTitles = styled.div`
  span {
    font-size: 13px;
    letter-spacing: 0.76px;
    transition: 0.4s all;
    position: relative;

    &.active {
      font-weight: bold;
      color: ${props => props.theme.colorPrimary};
    }
  }
`;

const StyledHRLine = styled.div`
  hr {
    flex: 1;
    opacity: 1;

    &.active {
      flex: 1;
      border-bottom: 2px solid;
      opacity: 1;
    }
  }
`;

const Tabs = ({ tabs, defaultActive, isLinkable }) => {
  const [activeTabKey, setActiveTab] = useState(null);

  useEffect(() => {
    if (tabs && tabs.length) {
      setActiveTab(defaultActive || tabs[0].key);
    }
  }, []);
  const activeTab = tabs.find(item => item.key === activeTabKey);
  const onTabChange = tab => () => setActiveTab(tab.key);

  return (
    <div className="tabs">
      <StyledTitles className="tabs-title flex">
        {tabs.map(tab => (
          <span
            className={classnames(
              'flex flex--1 align-items-center justify-content-center',
              { active: !isLinkable && activeTabKey === tab.key }
            )}
            key={tab.key}
            onClick={onTabChange(tab)}
          >
            {tab.label}
          </span>
        ))}
      </StyledTitles>
      <StyledHRLine className="flex">
        {tabs.map(tab => {
          return (
            <hr
              key={tab.key}
              className={classnames('mt-3 mb-3', {
                active: !isLinkable && activeTabKey === tab.key,
              })}
            />
          );
        })}
      </StyledHRLine>
      <div className="tabs-content">
        {activeTab && activeTab.component ? activeTab.component : null}
      </div>
    </div>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  defaultActive: PropTypes.string,
  isLinkable: PropTypes.bool,
};

Tabs.defaultProps = {
  defaultActive: null,
  isLinkable: false,
};

export default Tabs;

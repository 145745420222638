import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import styled from 'styled-components';

// ACTIONS
import { addNotification } from 'redux/GlobalUI/GlobalUI.actions';

// Notifications
import Notifications from './Notifications';
import Notification from './Notification';

const StyledNotifications = styled.div`
  position: fixed;
  top: 20px;
  left: 30px;
  right: 30px;
  z-index: 9999;
`;

const notificationsSelector = createSelector(
  state => state.globalUI,
  globalUI => globalUI.notifications
);

const NotificationsProvider = ({ children }) => {
  const dispatch = useDispatch();
  const notifications = useSelector(notificationsSelector);
  const notification = {
    success: message => {
      dispatch(
        addNotification({
          type: 'success',
          message,
        })
      );
    },
    error: message => {
      dispatch(
        addNotification({
          type: 'error',
          message,
        })
      );
    },
  };
  return (
    <Notifications.Provider value={{ notification }}>
      <>
        {children}
        <StyledNotifications id="notifications">
          {notifications.map((item, itemIndex) => (
            <Notification
              key={itemIndex}
              {...item}
              notificationIndex={itemIndex}
            />
          ))}
        </StyledNotifications>
      </>
    </Notifications.Provider>
  );
};

NotificationsProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.node,
    PropTypes.string,
  ]),
};
NotificationsProvider.defaultProps = {
  children: null,
};

export default NotificationsProvider;

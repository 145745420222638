import React from 'react';
import styled from 'styled-components';

const StyledSpinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .rolling {
    position: relative;
    width: 24px;
    height: 24px;
    & > div {
      top: 50%;
      left: 50%;
      animation: lds-rolling 1s linear infinite;
      position: absolute;
      width: 100%;
      height: 100%;
      border: 2px solid #0e5c67;
      border-top-color: transparent;
      border-radius: 50%;
    }
  }

  @keyframes lds-rolling {
    0% {
      -webkit-transform: translate(-50%, -50%) rotate(0deg);
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      -webkit-transform: translate(-50%, -50%) rotate(360deg);
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
`;

const Spinner = () => (
  <StyledSpinner className="spinner">
    <div className="rolling">
      <div />
    </div>
  </StyledSpinner>
);
export default Spinner;

import spring from 'react-motion/lib/spring';

export function ensureSpring(styles) {
  const obj = {};

  for (var key in styles) {
    const value = styles[key];
    if (typeof value === 'number') {
      obj[key] = spring(value);
    } else {
      obj[key] = value;
    }
  }

  return obj;
}

export function identity(v) {
  return v;
}

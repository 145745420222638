import { takeLatest } from 'redux-saga/effects';
import { doSagaActionGenerator } from 'utils/sagaHelpers';
import * as types from './User.actionTypes';
import UserService from 'services/User.service';

// FLOW
import { fetchUserInformationFlow } from './User.actions';

export function* fetchUserInformaton() {
  yield takeLatest(types.FETCH_USER_INFORMATION_REQUEST, action =>
    doSagaActionGenerator({
      action,
      flow: fetchUserInformationFlow,
      apiService: UserService.fetchUserInformation,
    })
  );
}

export const initialState = {
  activeStep: 1,
  finishedStep: 0,
  details: {
    firstName: '',
    lastName: '',
    email: '',
    dob: null,
    ssn: '',
    phone: '',
    verificationCode: [],
    pinCode: [],
    confirmPinCode: [],
    bank: null,
    accounts: [],
  },
};

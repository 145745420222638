const API = {
  authentication: `/auth`,
  users: `/users`,
  merchants: `/merchants`,
  stores: `/stores`,
  global: ``,
  transaction: '/transactions',
};

export default API;

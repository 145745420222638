import { createSelector } from 'reselect';

const bankAccountSelector = () => state => state.bankAccount;
export const userBankAccountSelector = () =>
  createSelector(bankAccountSelector(), bankAccountState => bankAccountState);

export const userAccountsSelector = () =>
  createSelector(
    bankAccountSelector(),
    bankAccountState => bankAccountState.accountIDs || []
  );

export const userDefaultAccountSelector = () =>
  createSelector(
    bankAccountSelector(),
    bankAccountState => bankAccountState.defaultAccountID || null
  );

export const userBankAccountsSelector = () =>
  createSelector(
    bankAccountSelector(),
    bankAccountState => bankAccountState.list
  );

export const userBankAccountDetailssSelector = () =>
  createSelector(
    bankAccountSelector(),
    bankAccountState => bankAccountState.single
  );
export const userAccountssSelector = () =>
  createSelector(
    bankAccountSelector(),
    bankAccountState => bankAccountState.accounts
  );

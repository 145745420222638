import Apis from 'apis';
import { createService } from 'utils/serviceFactory';

const UserService = createService(Apis.users, {
  createTransaction: {
    method: 'POST',
    url: `${Apis.transaction}`,
  },
  fetchTransactions: {
    method: 'GET',
    url: `${Apis.users}/me/transactions`,
  },
  fetchTransactionDetails: {
    method: 'GET',
    url: `${Apis.users}/me/transactions/:transactionID`,
  },
  updateTransactionDetails: {
    method: 'PUT',
    url: `${Apis.transaction}/:transactionID`,
  },
  fetchTransactionEmployee: {
    method: 'GET',
    url: `${Apis.merchants}/:merchantId/stores/:storeId/transactions`,
  },
});

export default UserService;

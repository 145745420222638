import socketIOClient from 'socket.io-client';
import { getToken } from 'utils/tokenHelper';

export default class SocketClass {
  socket = {};
  static instance;

  constructor() {
    const API_URL = process.env.REACT_APP_API;
    this.socket = socketIOClient(API_URL, {
      query: {
        token: getToken(),
      },
    });
  }

  static getInstance() {
    if (!SocketClass.instance) {
      SocketClass.instance = new SocketClass();
    }

    return SocketClass.instance;
  }

  triggerEvent(eventName, callback) {
    return this.socket.on(eventName, data => {
      callback(data);
    });
  }

  emitEvent(eventName, data) {
    return this.socket.emit(eventName, data);
  }
}

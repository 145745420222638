// INITIAL STATE
import { initialState } from './GlobalUI.initialState';

// TYPES
import * as types from './GlobalUI.actionTypes';

export default (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_NOTIFICATION: {
      return {
        ...state,
        notifications: [...state.notifications, { ...action.notification }],
      };
    }

    case types.REMOVE_NOTIFICATION: {
      const clonedNotifications = [...state.notifications];
      clonedNotifications.splice(action.notificationIndex, 1);
      return {
        ...state,
        notifications: clonedNotifications,
      };
    }

    case types.TOGGLE_MENU: {
      return {
        ...state,
        menuOpened:
          typeof action.open === 'undefined' ? !state.menuOpened : action.open,
      };
    }

    default:
      return { ...state };
  }
};

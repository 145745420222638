import Apis from 'apis';
import { createService } from 'utils/serviceFactory';

const UserService = createService(Apis.users, {
  fetchBankAccounts: {
    method: 'GET',
    url: `${Apis.users}/me/items`,
  },
  fetchBankAccountDetails: {
    method: 'GET',
    url: `${Apis.users}/me/items/:itemID`,
  },
  setDefaultBankAccount: {
    method: 'PUT',
    url: `${Apis.users}/me/items/:itemID/account/default`,
  },
  removeBankAccount: {
    method: 'DELETE',
    url: `${Apis.users}/me/items/:itemID`,
  },
  addBankAccount: {
    method: 'POST',
    url: `${Apis.users}/me/items/add`,
  },
  createPasses: {
    method: 'POST',
    url: `${Apis.users}/me/passes`,
  },
  fetchAccounts: {
    method: 'GET',
    url: `${Apis.users}/me/items/:itemID/accounts`,
  },
  // Fetch User Information for Employee App
  fetchUserInformation: {
    method: 'GET',
    url: `${Apis.users}/:userId`,
  },
});

export default UserService;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledBadge = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  background-color: ${props => {
    if (props.type === 'success') return props.theme.colorPrimary;
    else if (props.type === 'danger') return props.theme.colorDanger;
    return props.theme.colorPlaceholder;
  }};
  color: #fff;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 18px;
  box-shadow: ${props => {
    if (props.type === 'success') return props.theme.primaryBoxShadow;
    else if (props.type === 'danger') return props.theme.dangerBoxShadow;
    return 'none';
  }};
`;
const Badge = ({ children, type }) => {
  return <StyledBadge type={type}>{children}</StyledBadge>;
};

Badge.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.number,
  ]),
  type: PropTypes.string,
};
Badge.defaultProps = {
  children: null,
  type: null,
};

export default Badge;

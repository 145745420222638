import { combineReducers } from 'redux';

// REDUCERS
import bankAccount from './BankAccount/BankAccount.reducer';
import auth from './Auth/Auth.reducer';
import register from './Register/Register.reducer';
import globalUI from './GlobalUI/GlobalUI.reducer';
import reward from './Reward/Reward.reducer';
import transaction from './Transaction/Transaction.reducer';
import user from './User/User.reducer';
import stores from './Stores/Stores.reducer';
import { apiStatus } from './ApiStatus/ApiStatus.reducer';
import { connectRouter } from 'connected-react-router';
// import reservationSettings from './FormSubmissions/formSubmissions.reducer';

const rootReducer = history =>
  combineReducers({
    bankAccount,
    auth,
    register,
    globalUI,
    reward,
    stores,
    transaction,
    user,
    router: connectRouter(history),
    apiStatus,
  });

export default rootReducer;

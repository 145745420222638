// Employee Side
export const FETCH_STORES_BY_EMPLOYEE_REQUEST =
  'FETCH_STORES_BY_EMPLOYEE_REQUEST';
export const FETCH_STORES_BY_EMPLOYEE_SUCCESS =
  'FETCH_STORES_BY_EMPLOYEE_SUCCESS';
export const FETCH_STORES_BY_EMPLOYEE_FAILURE =
  'FETCH_STORES_BY_EMPLOYEE_FAILURE';

// Customer Side
export const FETCH_STORES_REQUEST = 'FETCH_STORES_REQUEST';
export const FETCH_STORES_SUCCESS = 'FETCH_STORES_SUCCESS';
export const FETCH_STORES_FAILURE = 'FETCH_STORES_FAILURE';

// Due to the separate api, the action types should be defined separately
export const FETCH_STORES_NEARBY_REQUEST = 'FETCH_STORES_NEARBY_REQUEST';
export const FETCH_STORES_NEARBY_SUCCESS = 'FETCH_STORES_NEARBY_SUCCESS';
export const FETCH_STORES_NEARBY_FAILURE = 'FETCH_STORES_NEARBY_FAILURE';

export const FETCH_STORE_PRODUCTS_REQUEST = 'FETCH_STORE_PRODUCTS_REQUEST';
export const FETCH_STORE_PRODUCTS_SUCCESS = 'FETCH_STORE_PRODUCTS_SUCCESS';
export const FETCH_STORE_PRODUCTS_FAILURE = 'FETCH_STORE_PRODUCTS_FAILURE';

export const FETCH_STORE_DETAIL_REQUEST = 'FETCH_STORE_DETAIL_REQUEST';
export const FETCH_STORE_DETAIL_SUCCESS = 'FETCH_STORE_DETAIL_SUCCESS';
export const FETCH_STORE_DETAIL_FAILURE = 'FETCH_STORE_DETAIL_FAILURE';

export default class EventEmitter {
  events = {};
  static instance;
  constructor() {}

  static getInstance() {
    if (!EventEmitter.instance) {
      EventEmitter.instance = new EventEmitter();
    }

    return EventEmitter.instance;
  }

  emitEvent(event, data) {
    if (!this.events[event]) return;

    this.events[event].forEach(callback => callback(data));
  }

  onEvent(event, callback) {
    if (!this.events[event]) this.events[event] = [];
    this.events[event].push(callback);
  }
}
